import quoteService from '@/services/quoteService';
import helperService from '@/services/helperService';

export default {
	name: 'size',
	data: function() {
		return {
			step: 2,
			sizes: [
				{ key: '1 bedroom', value: '1', type: 'apartment' },
				{ key: '2 bedroom', value: '2', type: 'house' },
				{ key: '3 bedroom', value: '3', type: 'house' },
				{ key: '4 bedroom', value: '4', type: 'house' },
				{ key: '5+ bedroom', value: '5', type: 'house' },
				{ key: 'Office', value: '5', type: 'office' },
			],
			selectedSize: {},
			selectedIndex: null,
		};
	},
	beforeRouteEnter: function(to, from, next) {
		if (from.name || helperService.isValidLandingState(2)) {
			next(true);
		} else {
			next('/');
		}
	},
	created: function() {
		this.checkForQuoteData();
	},
	mounted: function() {
		helperService.updateProgress(this.step);

		if (quoteService.getMoveLeadToken()) {
			if (window.analytics) {
				window.analytics.page(
					'Move_size',
					{ type: 'page', path: 'Move_size' },
					{ integrations: { AdWords: false } }
				);
			}
		} else {
			document.addEventListener('fetchID', () => {
				if (window.analytics) {
					window.analytics.page(
						'Move_size',
						{ type: 'page', path: 'Move_size' },
						{ integrations: { AdWords: false } }
					);
				}
			});
		}
	},
	methods: {
		checkForQuoteData: function() {
			const quote = quoteService.getQuote();

			this.$log.info(quote);

			if (quote && !quote.origin_zip) {
				this.$router.replace('/secure');

				return false;
			}

			if (quote && quote.move_size) {
				this.selectedSize = {
					key: quote.size_key,
					value: quote.move_size,
					type: quote.size_type,
				};
				this.selectedIndex = this.sizes.findIndex(size => {
					return this.selectedSize.key === size.key;
				});
			}
		},
		setMoveSize: function(index) {
			this.selectedSize = this.sizes[index];
			this.selectedIndex = index;

			quoteService.setSize(this.selectedSize);
			helperService.init();

			this.$router.push('/shipping');
		},
	},
};
